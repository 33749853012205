import {DateTime} from 'luxon';
export default function (value) {
  if (!value) {
    return 'N/A';
  } else if (typeof value === 'number') {
    // return DateTime.fromSeconds(value).toFormat();
  } else if (value.seconds) {
    return DateTime.fromSeconds(value.seconds).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
  } else {
    return value;
  }
};
