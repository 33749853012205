<template>
  <main>
    <section class="section" style="padding-bottom: 0;">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation-emails" :bid="bid" :rid="rid" label="breadcrumb_relation_emails"/>
        </ul>
      </nav>
      <div class="field">
        <sqr-router-link
          class="field"
          icon="envelope"
          label="emails_welcome"
          :to="{name: 'relation-email', params: {bid, rid}, query: {templateId: 'welcome'}}"
          color="primary"
        />
      </div>
    </section>
    <sqr-progress v-show="loading" />
    <sqr-error :error="error"/>
    <section v-if="!error">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{$t('emails_date')}}</th>
            <th>{{$t('emails_to')}}</th>
            <th>{{$t('emails_template')}}</th>
            <th>{{$t('emails_subject')}}</th>
            <th>{{$t('emails_delivery_state')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in records" :key="email.id">
            <td>{{email.delivery && email.delivery.startTime | epoch}}</td>
            <td>{{email.to}}</td>
            <td>{{email.template && email.template.templateId}}</td>
            <td>{{email.subject || email.message && email.message.subject}}</td>
            <td>
              <div class="tag">
                {{email.delivery && email.delivery.state}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="padding: 0 0.75rem;">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import SqrFirePage from '@/sqrd/SqrFirePage';
import buildRef from '@/utils/buildRef';
import relation from './relation';
import epoch from '@/filters/epoch';

export default {
  mixins: [relation],
  components: {SqrFirePage},
  filters: {
    epoch
  },
  computed: {
    ...mapState('emails', ['records', 'loading', 'loadError']),
    ...mapGetters('emails', ['pagination'])
  },
  mounted () {
    const path = ['brokers', this.bid, 'emails'];
    const orderBy = [['delivery.startTime', 'desc']];
    const where = [['relationId', '==', this.rid]];
    this.sub({path, where, orderBy, limit: 50});
  },
  methods: {
    ...mapActions('emails', ['sub', 'pageSet', 'pageSize'])
  }
}
</script>
